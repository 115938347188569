import React, { useState, useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Routes from "./components/routes/Routes";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Footer from "./components/footer/footer";

import "./App.scss";
import "./modal.scss";

const App = () => {
  const [settings, setSettings] = useState([]);
  const [component, setComponent] = useState([{ key: "initial" }]);
  const [modal, setModal] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    loadSettings();
    const interval = setInterval(pageReload, 1000 * 60 * 60 * 12); // Reload every 12 hours
    setIntervalId(interval);
    document.addEventListener("keydown", settingsHandler, false);

    return () => {
      clearInterval(intervalId);
      document.removeEventListener("keydown", settingsHandler, false);
    };
  }, []); // Empty dependency array to run once on mount

  const settingsHandler = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      toggleModal();
    }
  };

  const toggleModal = () => setModal((prevModal) => !prevModal);

  const loadSettings = async () => {
    const savedSettings = window.localStorage.getItem("settings");
    if (savedSettings) {
      const parsedSettings = JSON.parse(savedSettings);

      // Update the settings state directly
      setSettings(parsedSettings);

      // Optionally, also update the components if needed
      const updatedComponents = parsedSettings.map((val) => {
        let component = {
          key: `${val.component}${val.color}`,
          color: val.color,
        };
        switch (val.component) {
          case "route":
            component.component = (
              <Routes
                next={pageSwitch}
                color={val.color}
                cycleDurationMs={20e3}
              />
            );
            break;
          default:
            break;
        }
        return component;
      });

      // Update the component state as well (if required)
      setComponent(
        updatedComponents.length ? updatedComponents : [{ key: "initial" }]
      );
    }
  };

  const saveSettings = (e) => {
    e.preventDefault();
    window.localStorage.setItem("settings", JSON.stringify(settings));
    window.location.reload();
  };

  const pageReload = () => window.location.reload();

  const pageSwitch = (next) => {
    setComponent((prevComponent) => {
      const updatedComponent = next
        ? [...prevComponent.slice(1), prevComponent[0]]
        : [
            prevComponent[prevComponent.length - 1],
            ...prevComponent.slice(0, -1),
          ];
      return updatedComponent;
    });
  };

  const handleChange = (e) => {
    const { name, value, dataset } = e.target;
    const updatedSettings = [...settings];
    updatedSettings[dataset.id][name] = value;
    setSettings(updatedSettings);
  };

  const addScreen = () => {
    setSettings((prevSettings) => [
      ...prevSettings,
      { component: "", color: "" },
    ]);
  };

  const removeSetting = (e) => {
    e.preventDefault();
    const index = e.target.getAttribute("data-id");
    setSettings((prevSettings) =>
      prevSettings.filter((_, idx) => idx !== parseInt(index))
    );
  };

  const screens = () => {
    return [
      <option key="" value="" />,
      <option key="route" value="route">
        Route
      </option>,
    ];
  };

  const colors = () => {
    return [
      <option key="" value="" />,
      <option key="green" value="green">
        Groen
      </option>,
      <option key="blue" value="blue">
        Blauw
      </option>,
    ];
  };

  return (
    <div className="appContain">
      <TransitionGroup component="div">
        {component[0].key !== "initial" && (
          <CSSTransition
            key={component[0].key}
            classNames="shift"
            timeout={{ enter: 500, exit: 300 }}
          >
            {component[0].component}
          </CSSTransition>
        )}
      </TransitionGroup>
      <Footer />
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Settings</ModalHeader>
        <ModalBody>
          <form onSubmit={saveSettings} onChange={handleChange}>
            <button
              onClick={addScreen}
              type="button"
              className="btn btn-outline-primary mb-3"
            >
              Add new screen
            </button>
            {settings.map((val, idx) => {
              const settingId = `scherm-${idx}`;
              const colorId = `kleur-${idx}`;
              return (
                <div key={idx} className="settingGrid">
                  <label htmlFor={settingId}>{`Scherm #${idx + 1}`}</label>
                  <label htmlFor={colorId}>Kleur</label>
                  <label></label>
                  <select
                    name="component"
                    data-id={idx}
                    id={settingId}
                    value={val.component}
                    className="form-select"
                  >
                    {screens()}
                  </select>

                  <select
                    name="color"
                    data-id={idx}
                    id={colorId}
                    value={val.color}
                    className="form-select"
                  >
                    {colors()}
                  </select>

                  <div className="closeSetting">
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      data-id={idx}
                      onClick={removeSetting}
                    ></button>
                  </div>
                </div>
              );
            })}
            <input type="submit" value="Submit" className="btn btn-success" />
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default App;
