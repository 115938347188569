import React, { useState, useEffect } from "react";
import { ReactComponent as Checkmark } from "./checkmark.svg";

const Newline = ({ info, delay }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setShow(true);
    }, delay);

    return () => {
      clearTimeout(intervalId); // Cleanup the timeout
    };
  }, [delay]);

  const combineDateAndTime = (start, end) => {
    return "wrapper";
    /*
    const now = new Date();
    const dateString = `${now.getFullYear()}-${
      now.getMonth() + 1
    }-${now.getDate()}`;
    const startTime = new Date(`${dateString} ${start}`);
    const endTime = new Date(`${dateString} ${end}`);

    if (now > startTime && now > endTime) {
      return "wrapper done";
    } else if (now > startTime) {
      return "wrapper current";
    } else {
      return "wrapper";
    }
      */
  };

  const wrapperClass = combineDateAndTime(info.start, info.delivered);

  return (
    <div className={`routesGrid ${wrapperClass} ${show && "show"}`}>
      <div className="blocks">{info.route_name}</div>
      <div className="blocks">{info.number_orders}</div>
      <div className="blocks">{info.completed}%</div>
      <div className="blocks">
        {info.completed === 100 && <Checkmark key="img" />}
      </div>
    </div>
  );
};

export default Newline;
