import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import Page from "./page";
import "react-circular-progressbar/dist/styles.css";
import "./Routes.scss";
import "./Ticker.scss";
import { api } from "../../config";

const Routes = ({ next, color, cycleDurationMs = 60e3 }) => {
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const intervalRef = useRef(null);
  const [timePassedPercentage, setTimePassedPercentage] = useState(0);

  const maxlines = 10;
  const { lines, pageCount } = useMemo(() => {
    let result = [];
    let index = 0;

    // Divide the data into chunks of `maxlines`
    while (index < data.length) {
      result.push(data.slice(index, index + maxlines));
      index += maxlines;
    }

    // Calculate the total number of pages
    const pageCount = result.length;
    return { lines: result, pageCount: pageCount };
  }, [data]);

  const pageswitch = useCallback(
    (direction) => {
      setPage((prevPage) => {
        if (direction === "next") {
          return prevPage < pageCount - 1 ? prevPage + 1 : 0; // Loop to the first page
        } else if (direction === "prev") {
          return prevPage > 0 ? prevPage - 1 : pageCount - 1; // Loop to the last page
        }
        return prevPage;
      });
    },
    [pageCount]
  );

  const resetTimer = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // Reset the percentage to 0 and start a new timer
    setTimePassedPercentage(0);

    const intervalStepMs = 1e3; // Update every second
    const percentageStep = (intervalStepMs / cycleDurationMs) * 100;

    intervalRef.current = setInterval(() => {
      setTimePassedPercentage((prev) => {
        const next = prev + percentageStep;
        if (next >= 100) {
          clearInterval(intervalRef.current);
          setTimePassedPercentage(0); // Reset percentage
          pageswitch("next"); // Trigger the next page
          resetTimer(); // Immediately reset the timer for the next cycle
        }
        return next;
      });
    }, intervalStepMs);
  }, [cycleDurationMs, pageswitch]);

  const force = useCallback(
    (e) => {
      if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
        resetTimer(); // Reset the interval timer
        if (e.key === "ArrowLeft") {
          pageswitch("prev");
        } else {
          pageswitch("next");
        }
      }
    },
    [pageswitch, resetTimer] // Add necessary dependencies
  );

  const loadData = useCallback(() => {
    fetch(api + "/plugins/vitamientje_module/getCastRouteStatus")
      .then((res) => res.json())
      .then((response) => {
        if (response.length > 0) {
          const updatedResponse = response.map((item, index) => ({
            ...item,
            resPos: index,
          }));
          setData(updatedResponse);
          setPage(0);
          resetTimer(); // Initialize the interval
        } else {
          next(true);
        }
      })
      .catch((error) => console.error("Error:", error));
  }, [next, resetTimer]);

  // Memoize the setupDataLoadInterval function to avoid unnecessary re-creations
  const setupDataLoadInterval = useCallback(() => {
    const intervalId = setInterval(loadData, 5 * 60 * 1e3); // 5 minutes = 300000 milliseconds

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, [loadData]); // Make sure loadData is included if it's changing

  useEffect(() => {
    loadData();

    const handleKeydown = (e) => {
      force(e);
    };

    // Set up the interval to call loadData every 5 minutes
    const cleanup = setupDataLoadInterval();

    // Add keydown event listener
    document.addEventListener("keydown", handleKeydown);

    // Clean up both the event listener and the interval on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeydown);
      cleanup(); // Call the cleanup function to clear the interval
    };
  }, [force, loadData, setupDataLoadInterval]);

  return (
    <div key="route" className={`routes ${color}`}>
      <div className="pages">
        <CircularProgressbar
          key={page}
          className="fast-animation"
          value={timePassedPercentage + 1}
          text={`${page + 1}/${lines.length}`}
        />
        <div className="pagecounter" />
      </div>
      <div className="holder">
        <div className="header routesGrid">
          <div className="blocks">
            <span>Route</span>
          </div>
          <div className="blocks">
            <span>Orders</span>
          </div>
          <div className="blocks">
            <span>Gedaan</span>
          </div>
          <div className="blocks">
            <span />
          </div>
        </div>
        {lines.map((dates, i) => {
          if (i === page) return <Page key={i} data={dates} />;
        })}
      </div>
    </div>
  );
};

export default Routes;
