import React, { useEffect, useState } from "react";
import Newline from "./newline";

const Page = ({ data }) => {
  const [lines, setLines] = useState(data);

  useEffect(() => {
    setLines(data);
    console.log(data);
  }, [data]);

  const showLines = () => {
    let showline = 0;
    return (
      <div className="page show">
        {lines.map((line) => {
          showline += 200;
          return <Newline key={line.resPos} info={line} delay={showline} />;
        })}
      </div>
    );
  };

  return showLines();
};

export default Page;
